/**
 * Registers a computed getter/setter for nested attributes of a parent object
 * @param {string} parent the object with nested attributes
 * @param {string[]|object} attributes the nested attributes that need a computed getter/setter. Pass { `key`: 'mappedKey' } for name overrides
 * @returns {object} a set of computed getters/setters
 */
export const mapNestedProps = (parent, attributes = []) => {
  // The attributes API is flexible for code-minimization. Supports the following:
  // 1. Array of keys. ['page']
  // 2. Object containing key-mappings. { page: 'syncedPage' }
  // 3. Array of objects (good for router query sanitization). [{ source: 'page', target: 'syncedPage', sanitize: () => true }]
  const keys = Array.isArray(attributes)
    ? attributes.map((attribute) =>
        typeof attribute === 'string'
          ? { source: attribute, target: attribute }
          : attribute
      )
    : Object.entries(attributes).reduce(
        (acc, [source, target]) => [...acc, { source, target }],
        []
      )
  return keys.reduce(
    (acc, { source, target }) =>
      target
        ? Object.assign(acc, {
            [target]: {
              get() {
                const parentValue = this[parent]
                if (
                  parentValue === undefined ||
                  parentValue[source] === undefined
                )
                  return
                return parentValue[source]
              },
              set(value) {
                this[parent] = { ...this[parent], [source]: value }
              },
            },
          })
        : acc,
    {}
  )
}

/**
 * Wraps a v-model prop with a computed getter/setter
 * @param {string} key a name for your `value` prop
 * @param {string[]|object} [attributes] - a list of the prop's nested attributes if the prop is an object
 * @returns {object} a set of computed getters/setters
 */
export const mapModel = (key, attributes = []) => ({
  [key]: {
    get() {
      return this.value
    },
    set(value) {
      this.$emit('input', value)
    },
  },
  ...mapNestedProps(key, attributes),
})
