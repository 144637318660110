<template>
  <LeftFlyoutLayout :is-open="isOpen">
    <template #flyout-content>
      <OwnStack class="builder-page-content__editor-container h-full">
        <slot name="editor"></slot>
      </OwnStack>
    </template>

    <template #default>
      <div class="builder-page-content__preview h-full">
        <slot name="preview"></slot>
      </div>
    </template>
  </LeftFlyoutLayout>
</template>

<script>
import { LeftFlyoutLayout } from '@/components/layouts'
import { OwnStack } from '@/ui'

export default {
  name: 'BuilderPageContent',
  components: {
    LeftFlyoutLayout,
    OwnStack,
  },
  props: {
    /**
     * Control whether flyout is open from parent.
     */
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.builder-page-content {
  &__editor-container {
    @include flyout-shadow();
    z-index: 10;
  }

  &__editor-actions {
    padding: 16px;
    border-top: 1px solid $background-divider;
  }

  &__preview {
    background-color: $background-secondary;
    overflow-y: auto;
    z-index: 5;
    padding: 40px 16px;

    @include respond-to('md-and-up') {
      padding: 40px 48px;
    }
  }
}
</style>
