<i18n locale="en">
{
  "labels": {
    "order-online": "Order Online"
  },
  "bottom-links": {
    "home": "Home",
    "team": "Meet Our Team",
    "events": "Caterings & Events",
    "giftCards": "Gift Cards",
    "careers": "Careers",
    "terms": "Terms Of Use",
    "privacy": "Privacy Policy",
    "accessibility": "Accessibility Statement"
  },
  "legalSection": "Small print",
  "copyright": "2024 All Rights Reserved",
  "madeWithOwner": "Made with Owner"
}
</i18n>

<template>
  <div class="footer-preview">
    <div class="footer-preview__top">
      <PreviewSheet
        class="footer-preview__container"
        background="secondary"
        radius="large"
      >
        <OwnGrid class="footer-preview__main-grid">
          <PreviewLogo />
          <OwnGrid class="footer-preview__link-grid">
            <PreviewTypography
              :text="$t('bottom-links.home')"
              variant="paragraphRegular"
              color="primary"
            />
            <PreviewTypography
              :text="$t('bottom-links.events')"
              variant="paragraphRegular"
              color="primary"
            />
            <PreviewTypography
              :text="$t('bottom-links.careers')"
              variant="paragraphRegular"
              color="primary"
            />
            <PreviewTypography
              :text="$t('bottom-links.team')"
              variant="paragraphRegular"
              color="primary"
            />
            <PreviewTypography
              :text="$t('bottom-links.giftCards')"
              variant="paragraphRegular"
              color="primary"
            />
          </OwnGrid>
          <div class="footer-preview__button-container">
            <PreviewButton :title="$t('labels.order-online')" />
          </div>
          <PreviewHr class="footer-preview__divider" />

          <PreviewTypography
            :text="$t('legalSection')"
            variant="paragraphRegular"
            color="secondary"
          />
          <OwnGrid class="footer-preview__link-grid">
            <PreviewTypography
              :text="$t('bottom-links.terms')"
              variant="paragraphRegular"
              color="secondary"
            />
            <PreviewTypography
              :text="$t('bottom-links.privacy')"
              variant="paragraphRegular"
              color="secondary"
            />
            <PreviewTypography
              :text="$t('bottom-links.accessibility')"
              variant="paragraphRegular"
              color="secondary"
            />
          </OwnGrid>
        </OwnGrid>
      </PreviewSheet>
    </div>
    <OwnStack class="footer-preview__bottom" row>
      <OwnGrid class="footer-preview__bottom-grid">
        <OwnStack
          class="footer-preview__socials"
          align="center"
          row
          spacing="2"
        >
          <!-- Facebook Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path
              fill="currentColor"
              d="M9 1.8A7.2 7.2 0 0 0 1.8 9c0 3.61 2.66 6.59 6.124 7.111v-5.203H6.142V9.015h1.782V7.756c0-2.085 1.015-3 2.748-3 .83 0 1.27.062 1.477.09v1.651h-1.182c-.736 0-.993.698-.993 1.484v1.034h2.156l-.292 1.893H9.974v5.218C13.49 15.65 16.2 12.645 16.2 9A7.2 7.2 0 0 0 9 1.8Z"
            ></path>
          </svg>
          <!-- Instagram Icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M5.999 1.8A4.205 4.205 0 0 0 1.8 6v6A4.205 4.205 0 0 0 6.001 16.2h6a4.205 4.205 0 0 0 4.199-4.201v-6a4.205 4.205 0 0 0-4.201-4.2h-6ZM13.2 4.2a.6.6 0 1 1 0 1.2.6.6 0 0 1 0-1.2ZM9 5.4c1.985 0 3.6 1.614 3.6 3.6 0 1.985-1.615 3.6-3.6 3.6A3.604 3.604 0 0 1 5.4 9c0-1.986 1.615-3.6 3.6-3.6Zm0 1.2a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8Z"
            ></path>
          </svg>
        </OwnStack>
        <OwnStack row justify="center" align="center">
          <PreviewTypography
            :text="`${smsSettings?.legalCompanyName || ''} ${$t('copyright')}`"
            variant="paragraphRegular"
            color="secondary"
          />
        </OwnStack>
        <OwnStack row justify="end" align="center">
          <OwnStack
            class="footer-preview__owner"
            align="center"
            row
            spacing="2"
          >
            <PreviewTypography
              :text="$t('madeWithOwner')"
              variant="paragraphRegular"
              color="primary"
            />
            <!-- Owner Icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              height="20px"
              width="20px"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10ZM4.263 10c0-.257.085-.506.242-.709L6.64 6.527c.361-.468.542-.702.769-.87.2-.15.427-.26.668-.329.273-.076.568-.076 1.16-.076h1.524c.592 0 .887 0 1.16.076.24.068.467.18.668.329.227.168.408.402.77.87l2.135 2.764c.157.203.242.452.242.709 0 .32-.108.631-.306.883l-2.071 2.619c-.362.457-.543.686-.768.85-.2.147-.425.255-.663.322-.269.074-.56.074-1.144.074H9.238c-.592 0-.887 0-1.16-.076a2.052 2.052 0 0 1-.668-.329c-.227-.168-.408-.402-.77-.87l-2.135-2.764A1.16 1.16 0 0 1 4.263 10Zm7.928-.499L10.163 6.85c-.055-.073-.083-.109-.117-.122a.128.128 0 0 0-.092 0c-.034.013-.061.05-.117.122L7.81 9.5c-.136.179-.204.268-.23.367a.513.513 0 0 0 0 .264c.026.099.094.188.23.366l2.028 2.652c.056.072.083.109.117.122.03.011.063.011.092 0 .034-.013.062-.05.117-.122l2.028-2.652c.136-.178.205-.267.23-.366a.514.514 0 0 0 0-.264c-.025-.099-.094-.188-.23-.367Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </OwnStack>
        </OwnStack>
      </OwnGrid>
    </OwnStack>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'

import { ConfiguredClient } from '@/api'
import { SmsSettings } from '@/core'
import {
  PreviewButton,
  PreviewHr,
  PreviewLogo,
  PreviewSheet,
  PreviewTypography,
  useMergedThemeComp,
} from '@/lib/builder'
import { logError } from '@/logger'
import { useMappedGetter } from '@/store'
import { OwnStack, OwnGrid } from '@/ui'

const brandId = useMappedGetter<string | undefined>('core/brand/active/id')

const theme = useMergedThemeComp()

const smsSettings = ref<SmsSettings>()

const fetchSMSData = async (brandId: string) => {
  try {
    smsSettings.value =
      await ConfiguredClient.sms.v1.brands.brand.getSmsMarketingSettings({
        params: { brandId },
      })
  } catch (err) {
    logError(err)
  }
}

onMounted(() => {
  if (brandId?.value) {
    fetchSMSData(brandId?.value)
  }
})

const textPrimaryColor = computed(() => theme.value?.colors.text.primary)
</script>

<style lang="scss" scoped>
.footer-preview {
  padding: 8px 0 16px 0;

  &__top {
    padding: 0 8px;
  }

  &__container {
    padding: 48px 112px;
  }

  &__main-grid {
    grid-template-columns: 200px 1fr 200px;
    column-gap: 48px;
    row-gap: 40px;
  }

  &__link-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 8px;
    column-gap: 16px;
  }

  &__divider {
    grid-column: span 3 / span 3;
  }

  &__button-container {
    justify-self: flex-end;
  }

  &__bottom {
    padding: 0 120px;
    margin-top: 16px;

    &-grid {
      flex: 1;
      grid-template-columns: auto 1fr auto;
    }
  }

  &__owner {
    padding: 4px;
    padding-left: 12px;
    border: 1px solid v-bind(textPrimaryColor);
    border-radius: 9999px;
    color: v-bind(textPrimaryColor);

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__socials {
    color: v-bind(textPrimaryColor);

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
</style>
