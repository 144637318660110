<i18n locale="en">
{
  "placeholder": "Search for your location",
  "errors": {
    "no-location-address": "No location address associated with that location"
  }
}
</i18n>
<template>
  <OwnInput
    ref="autocompleteTarget"
    v-model="inputVal"
    :placeholder="$t('placeholder')"
    :borderless="borderless"
  >
    <template #prepend>
      <PhMagnifyingGlass class="text-color-secondary" size="16" weight="bold" />
    </template>
  </OwnInput>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { PhMagnifyingGlass } from 'phosphor-vue/dist/phosphor-vue.esm'
import { onBeforeUnmount, onMounted, ref, WritableComputedRef } from 'vue'

import { OwnInput } from '@/ui'
import { useI18n, useNotify } from '@/utils'
import { logError } from '@/utils/logging'

import { normalizeGooglePlace } from './normalizeGooglePlace'
import { ProcessedPlace } from './types'

const props = withDefaults(
  defineProps<{
    autofocus?: boolean
    borderless?: boolean
    errors?: string[]
    hideIcon?: boolean
    value?: string
  }>(),
  {
    autofocus: false,
    borderless: false,
    errors: () => [],
    hideIcon: false,
    value: '',
  }
)

const emit = defineEmits<(event: 'selected', value: ProcessedPlace) => void>()

const autocompleteWidget = ref()
const autocompleteTarget = ref<InstanceType<typeof OwnInput> | null>(null)

const inputVal = useVModel(props) as WritableComputedRef<string>

const notify = useNotify()
const i18n = useI18n()

const focusAutocompleteTarget = () => {
  if (autocompleteTarget.value) {
    const childInput =
      autocompleteTarget.value.$el.getElementsByTagName('input')

    if (childInput[0]) {
      childInput[0].focus()
    }
  }
}

const onPlaceChanged = () => {
  if (autocompleteWidget.value) {
    const selectedPlace = autocompleteWidget.value.getPlace()

    if (selectedPlace) {
      const place = normalizeGooglePlace(selectedPlace)

      if (place) {
        emit('selected', place)
        inputVal.value = ''
      } else {
        notify(i18n.$t('errors.no-location-address'), 'error')
      }
    }

    focusAutocompleteTarget()
  }
}

onBeforeUnmount(() => {
  if (autocompleteWidget.value) {
    google.maps.event.clearInstanceListeners(autocompleteWidget.value)
  }
})

onMounted(() => {
  if (autocompleteTarget.value) {
    const childInputs =
      autocompleteTarget.value.$el.getElementsByTagName('input')

    if (
      childInputs[0] &&
      !!google?.maps?.places?.Autocomplete &&
      !!google?.maps?.event?.addListener
    ) {
      autocompleteWidget.value = new google.maps.places.Autocomplete(
        childInputs[0],
        {
          componentRestrictions: { country: 'us' },
          types: ['restaurant', 'cafe', 'bar', 'bakery', 'food'],
        }
      )

      google.maps.event.addListener(
        autocompleteWidget.value,
        'place_changed',
        onPlaceChanged
      )
    } else {
      logError(`Unable to load Google Autocomplete`)
    }

    if (props.autofocus) {
      focusAutocompleteTarget()
    }
  }
})
</script>

<style lang="scss">
.pac-container {
  margin-top: 16px;

  @include control-shadow();
}

.pac-item {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  font-family: $font-family;
  @include type('text-small');
  color: $text-color-secondary;
}

.pac-icon {
  margin: 0;
  display: none;
}

.pac-item-query {
  @include type('text-paragraph');
  color: $text-color-secondary;
}

.pac-matched {
  color: $text-color-secondary;
}
</style>
