import { sanitizeUrl } from '@braintree/sanitize-url'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { type RawLocation } from 'vue-router'
import { useRouter } from 'vue-router/composables'

import { useMappedGetter } from '@/store'
import { generateToken } from '@/utils'

interface StripeStoreState {
  brandId: string
  locationId: string
  returnTo: RawLocation
  token: string
}

const HERMES_URL = import.meta.env.VITE_HERMES_URL

const SQUARE_URL = import.meta.env.VITE_SQUARE_URL
const SQUARE_CLIENT_ID = import.meta.env.VITE_SQUARE_CLIENT_ID
const CLOVER_URL = import.meta.env.VITE_CLOVER_URL
const CLOVER_CLIENT_ID = import.meta.env.VITE_CLOVER_CLIENT_ID

const STRIPE_CONNECT_URL = 'https://connect.stripe.com/oauth/authorize'
const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID

export interface StartConnectParams {
  locationId: string
  returnTo: RawLocation
}

export const useOauthStore = defineStore('oauth', () => {
  const router = useRouter()

  const getReturnUrl = (returnTo: RawLocation): string => {
    const processedUrl = router.resolve(returnTo).href

    const returnToUrl = new URL(HERMES_URL + processedUrl)

    return returnToUrl.pathname
  }

  const localstorageStripeState = useStorage<string | null>(
    'OAUTH__STRIPE_REQUEST',
    null
  )

  const setStripeState = (newState: StripeStoreState | null): void => {
    if (!newState) {
      localstorageStripeState.value = null
    } else {
      localstorageStripeState.value = JSON.stringify(newState)
    }
  }

  const stripeState = computed<StripeStoreState | null>(() =>
    localstorageStripeState.value
      ? (JSON.parse(localstorageStripeState.value) as StripeStoreState)
      : null
  )

  const startCloverConnect = ({
    locationId,
    returnTo,
  }: StartConnectParams): void => {
    const cloverLink = new URL(`${CLOVER_URL}/oauth/v2/authorize`)
    cloverLink.searchParams.set('client_id', CLOVER_CLIENT_ID)
    cloverLink.searchParams.set(
      'state',
      JSON.stringify({
        locationId,
        returnTo: getReturnUrl(returnTo),
      })
    )

    window.location.href = sanitizeUrl(cloverLink.toString())
  }

  const startSquareConnect = ({
    locationId,
    returnTo,
  }: StartConnectParams): void => {
    const SQUARE_SCOPES = [
      'ORDERS_READ',
      'ORDERS_WRITE',
      'PAYMENTS_READ',
      'PAYMENTS_WRITE',
    ] as const

    const squareLink = new URL(`${SQUARE_URL}/oauth2/authorize`)
    squareLink.searchParams.set('client_id', SQUARE_CLIENT_ID)
    squareLink.searchParams.set('scope', SQUARE_SCOPES.join(' '))
    squareLink.searchParams.set('session', 'false')
    squareLink.searchParams.set(
      'state',
      JSON.stringify({
        locationId,
        returnTo: getReturnUrl(returnTo),
      })
    )

    window.location.href = sanitizeUrl(squareLink.toString())
  }

  const startStripeConnect = ({
    locationId,
    returnTo,
  }: StartConnectParams): void => {
    const activeBrandId = useMappedGetter<string>('core/brand/active/id')

    const { href } = router.resolve({
      name: 'oauth.stripe',
    })

    const redirectUrl = new URL(window.location.origin)
    redirectUrl.pathname = href

    const token = generateToken(6)

    setStripeState({
      brandId: activeBrandId.value,
      locationId,
      returnTo,
      token,
    })

    const stripeLink = new URL(STRIPE_CONNECT_URL)
    stripeLink.searchParams.set('response_type', 'code')
    stripeLink.searchParams.set('scope', 'read_write')
    stripeLink.searchParams.set('redirect_uri', redirectUrl.toString())
    stripeLink.searchParams.set('client_id', STRIPE_CLIENT_ID)
    stripeLink.searchParams.set('state', token)

    window.location.href = sanitizeUrl(stripeLink.toString())
  }

  const resetStripe = (): void => {
    setStripeState(null)
  }

  return {
    resetStripe,
    startCloverConnect,
    startSquareConnect,
    startStripeConnect,
    stripeState,
  }
})
