<template>
  <OwnStack class="right-flyout-page-wrapper" row>
    <OwnScrollContainer
      class="right-flyout-page-wrapper__content--outer"
      :class="[
        showFlyout && 'right-flyout-page-wrapper__content--outer--open',
        !showFlyout && 'right-flyout-page-wrapper__content--outer--closed',
      ]"
    >
      <div
        :style="{ maxWidth: constrainedWidth }"
        class="right-flyout-page-wrapper__content--inner"
      >
        <slot
          name="default"
          :open="openFlyout"
          :close="closeFlyout"
          :is-open="showFlyout"
        ></slot>
      </div>
    </OwnScrollContainer>
    <transition name="right-flyout-page-wrapper__flyout-slide">
      <div
        v-if="showFlyout || isOpen"
        class="right-flyout-page-wrapper__flyout"
        :style="{ width: computedFlyoutWidth }"
      >
        <slot
          name="flyout-content"
          :open="openFlyout"
          :close="closeFlyout"
          :is-open="showFlyout"
        ></slot>
      </div>
    </transition>
  </OwnStack>
</template>
<script>
import { OwnScrollContainer, OwnStack } from '@/ui'
import { useBreakpoints } from '@/utils'

import PageWrapperMixin from '../mixins/PageWrapperMixin'

export default {
  name: 'RightFlyoutPageWrapper',
  components: {
    OwnScrollContainer,
    OwnStack,
  },
  mixins: [PageWrapperMixin],
  props: {
    /**
     * Width of flyout
     */
    flyoutWidth: {
      type: [Number, String],
      default: 500,
    },

    /**
     * Control whether flyout is open from parent.  Should not use open/close slots if using this.
     */
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFlyout: false,
    }
  },
  setup() {
    const { isDesktop } = useBreakpoints()

    return { isDesktop }
  },
  computed: {
    computedFlyoutWidth() {
      const { flyoutWidth } = this

      return this.isDesktop ? `${flyoutWidth}px` : '100%'
    },
  },
  methods: {
    closeFlyout() {
      this.showFlyout = false
    },
    openFlyout() {
      this.showFlyout = true
    },
  },
}
</script>
<style lang="scss" scoped>
.right-flyout-page-wrapper {
  width: 100%;
  height: 100%;

  &__content {
    z-index: 50;

    &--outer {
      width: 100%;
      height: 100%;
    }

    &--inner {
      margin: 0 auto;

      height: 100%;
    }
  }

  &__flyout {
    flex-shrink: 0;
    position: relative;
    transition: transform 0.15s ease-in-out;
    z-index: 100;

    &-slide-enter-active,
    &-slide-leave-active {
      transform: translateX(0);
    }

    &-slide-enter,
    &-slide-leave-to {
      transform: translateX(100%);
    }
  }
}
</style>
