<template>
  <OwnInput
    v-model="displayValue"
    type="number"
    :accessibility-id="accessibilityId"
    :end-text="endText"
    :disabled="disabled"
    :error="error"
    :step="step"
    :placeholder="placeholder"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { OwnInput } from '@/ui'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    disabled?: boolean
    endText?: string
    error?: boolean
    max?: number
    numericType?: 'int' | 'float'
    placeholder?: string
    step?: number
    value?: number
    /** When `true`, allow `value` to become `undefined`. Otherwise, force `value` to always be a number. */
    allowEmpty?: boolean
  }>(),
  {
    accessibilityId: undefined,
    allowEmpty: false,
    disabled: false,
    endText: undefined,
    error: false,
    max: undefined,
    numericType: 'int',
    placeholder: undefined,
    step: undefined,
    value: undefined,
  }
)

const emit = defineEmits<(name: 'input', value: number | undefined) => void>()

const displayValue = computed({
  get() {
    return props.value
  },
  set(newVal) {
    if (newVal) {
      let parsedValue
      if (props.numericType === 'int') {
        parsedValue = parseInt(newVal.toString(), 10)
      } else {
        parsedValue = parseFloat(newVal.toString())
      }

      if (props.max && parsedValue > props.max) {
        emit('input', props.max)
      } else {
        emit('input', parsedValue)
      }
    } else {
      emit('input', props.allowEmpty ? undefined : 0)
    }
  },
})
</script>
