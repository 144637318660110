<template>
  <!-- eslint-disable vue/no-v-html -- linkify escapes HTML, so there is no risk of XSS from this content -->
  <component
    :is="renderElement"
    v-if="!info && hotlinkUrls"
    :class="['own-type', renderClass]"
    v-html="linkifiedText"
  />
  <!-- eslint-enable -->

  <component
    :is="renderElement"
    v-else-if="!info && !hotlinkUrls"
    :class="['own-type', renderClass]"
    :title="title"
    v-text="text"
  />

  <OwnTypePopover
    v-else
    :align="align"
    :render-class="renderClass"
    :render-element="renderElement"
    :tabular="tabular"
    :text="text"
    :info="info"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { linkify } from '@/utils/linkify'

import {
  lookupFont,
  lookupFontColor,
  FontColor,
  FontType,
} from '../Common/fonts'

import OwnTypePopover from './OwnTypePopover.vue'

const props = withDefaults(
  defineProps<{
    /**
     * @default 'left'
     */
    align?: 'left' | 'center' | 'right'
    color?: FontColor
    /**
     * Element to render as. When not specified, an appropriate element type will be chosen based on the `variant`.
     */
    el?: // Should be `Extract<keyof HTMLElementTagNameMap, ...>`, but that breaks Vue's prop type inference
    | 'caption'
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'label'
      | 'li'
      | 'p'
      | 'span'
      | 'summary'
      | 'td'
      | 'th'
      | 'a'

    /**
     * @default false
     */
    hotlinkUrls?: boolean
    info?: string
    /**
     * @default false
     */
    preserveWhitespace?: boolean
    tabular?: boolean
    text: string | number
    title?: string
    /**
     * @default 'paragraph'
     */
    variant?: FontType
  }>(),
  {
    align: 'left',
    color: undefined,
    decoration: 'none',
    el: undefined,
    hotlinkUrls: false,
    info: undefined,
    preserveWhitespace: false,
    tabular: false,
    title: undefined,
    variant: 'paragraph',
  }
)

const colorClass = computed(() => {
  if (props.color) {
    return lookupFontColor(props.color)
  }

  switch (props.variant) {
    case 'heading':
      return 'text-color-primary'
    case 'title':
      return 'text-color-primary'
    default:
      return 'text-color-secondary'
  }
})

const linkifiedText = computed(() => {
  if (props.hotlinkUrls) {
    return linkify(props.text.toString(), {
      className: 'own-type__link',
    })
  }

  return props.text.toString()
})

const typeClass = computed(() => {
  return lookupFont(props.variant)
})

const renderClass = computed(() => {
  const classes = [
    typeClass.value,
    colorClass.value,
    `text--${props.align}`,
    `decoration-${props.decoration}`,
  ]

  if (props.tabular) {
    classes.push('tabular-nums')
  }

  if (props.preserveWhitespace) {
    classes.push(`preserve-whitespace`)
  }

  return classes.join(' ')
})

const renderElement = computed(() => {
  if (props.el) return props.el

  switch (props.variant) {
    case 'heading':
      return 'h1'
    case 'title':
      return 'h3'
    case 'subtitle':
      return 'h5'
    case 'subtitle-small':
      return 'h6'
    case 'button':
      return 'span'
    default:
      return 'p'
  }
})
</script>

<style lang="scss">
.own-type {
  &.tabular-nums {
    font-variant-numeric: tabular-nums;
  }

  &.preserve-whitespace {
    white-space: pre-line;
  }

  &.own-type__link {
    color: $misc-brand;
  }
}
</style>
