<template>
  <div class="flex-col">
    <label
      v-for="option of options"
      :key="option.value"
      class="own-radio-group__row flex-row align-center justify-between gap-1"
      :for="deconflict(option.value)"
    >
      <span class="flex-row align-center gap-3">
        <slot name="text-lhs" :item="option" />
        <OwnType
          :text="option.label"
          el="span"
          variant="subtitle"
          color="primary"
        />
      </span>

      <input
        :id="deconflict(option.value)"
        v-model="selectedValues"
        :value="option.value"
        type="radio"
      />
    </label>
  </div>
</template>

<script lang="ts" setup generic="T extends Option<string>">
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

import { generateToken } from '@/utils'

import { OwnType } from '../OwnType'
import { Option } from '../types'

const props = withDefaults(
  defineProps<{
    options?: T[]
    value?: string
  }>(),
  {
    options: () => [],
    value: undefined,
  }
)

const componentId = generateToken(8)
/**
 * Use a component instance-specific prefix to avoid conflicts with the `<input>` `id` attribute.
 * @param {string} id An identifier
 */
const deconflict = (id: string) => `${componentId}-${id}`

const selectedValues = useVModel(props) as WritableComputedRef<
  string | undefined
>
</script>

<style lang="scss">
input[type='radio'] {
  cursor: pointer;
  height: 24px;
  width: 24px;
  accent-color: $misc-brand;
}

.own-radio-group {
  &__row {
    padding: 12px 0;
    border-bottom: 1px dashed $background-divider;
    user-select: none;
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
</style>
