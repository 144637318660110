<i18n locale="en">
{
  "actions": {
    "add": "Add Brands"
  },
  "empty": "No brands selected"
}
</i18n>

<template>
  <div>
    <div class="flex-col gap-3">
      <OwnCardList v-if="selectedBrands.length" :value="selectedBrands" control>
        <template #item="{ item }">
          <div class="flex-row gap-3 justify-between">
            <div class="flex-row gap-3 align-center">
              <BrandLogo :brand="item" />
              <OwnType
                class="text--truncate"
                :text="item.name"
                :title="item.name"
                variant="subtitle"
                color="primary"
              />
            </div>

            <button class="flex-shrink-0 text-color-secondary">
              <PhXCircle size="24" @click="removeSelectedBrand(item.id)" />
            </button>
          </div>
        </template>
      </OwnCardList>

      <OwnOption v-else el="div">
        <DefaultEmptyState :message="$t('empty')">
          <template #icon>
            <PhBookBookmark size="24" />
          </template>
        </DefaultEmptyState>
      </OwnOption>

      <OwnButton :text="$t('actions.add')" @click="showAddBrandDialog = true" />
    </div>

    <BrandSelectorDialog
      v-if="showAddBrandDialog"
      :already-selected="selectedBrandIds"
      @select="onBrandSelect"
      @close="closeDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { PhBookBookmark, PhXCircle } from 'phosphor-vue/dist/phosphor-vue.esm'
import { onMounted, ref, watch } from 'vue'

import { DefaultEmptyState } from '@/components/empty'
import { OwnButton, OwnCardList, OwnType, OwnOption } from '@/ui'

import { type Brand } from '../../types'
import BrandLogo from '../BrandLogo.vue'

import BrandSelectorDialog from './BrandSelectorDialog.vue'
import { useBrandCache } from './useBrandCache'

const props = defineProps<{
  value: string[]
}>()

const selectedBrandIds = useVModel(props, 'value')

const showAddBrandDialog = ref(false)

const { addToCache, loadBrands, selectedBrands } =
  useBrandCache(selectedBrandIds)

const removeSelectedBrand = (removalId: string) => {
  const indexToRemove = selectedBrandIds.value.findIndex(
    (selectedId) => selectedId === removalId
  )
  selectedBrandIds.value.splice(indexToRemove, 1)
}

const closeDialog = () => {
  showAddBrandDialog.value = false
}

const onBrandSelect = (brands: Brand[]) => {
  addToCache(brands)

  for (const { id } of brands) {
    selectedBrandIds.value.push(id)
  }

  closeDialog()
}

watch(selectedBrandIds, async (value) => {
  await loadBrands(value)
})

onMounted(async () => {
  await loadBrands(selectedBrandIds.value)
})
</script>
