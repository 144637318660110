<template>
  <OwnCard
    class="own-toggle"
    :border-color="control ? 'background-divider' : 'transparent'"
    :background-color="control ? 'background-primary' : 'transparent'"
    :control="control"
    el="button"
    @keyup.enter="toggleActive"
    @click.stop="toggleActive"
  >
    <OwnStack
      :class="[control && 'own-toggle__trigger-control']"
      el="span"
      justify="between"
      align="center"
      spacing="2"
      row
    >
      <OwnStack justify="start" align="start" spacing="2" row>
        <slot name="prepend"></slot>
        <OwnStack v-if="label || description" spacing="1">
          <OwnType
            v-if="label"
            :text="label"
            class="own-toggle__text"
            el="label"
            variant="subtitle"
            color="primary"
          />
          <OwnType
            v-if="description"
            :text="description"
            class="own-toggle__text"
            el="span"
            variant="paragraph-small"
          />
        </OwnStack>
      </OwnStack>

      <ToggleSwitch class="flex-shrink-0" :active="inputValue" />
    </OwnStack>
  </OwnCard>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

import { OwnCard } from '../OwnCard'
import { OwnStack } from '../OwnStack'
import { OwnType } from '../OwnType'

import ToggleSwitch from './components/ToggleSwitch.vue'

interface OwnToggleProps {
  control?: boolean
  description?: string
  label?: string
  value?: boolean
}

const props = withDefaults(defineProps<OwnToggleProps>(), {
  control: false,
  description: undefined,
  label: undefined,
  value: false,
})

const inputValue = useVModel(props) as WritableComputedRef<boolean>

const toggleActive = () => {
  inputValue.value = !inputValue.value
}
</script>

<style lang="scss">
.own-toggle {
  width: 100%;

  &__text {
    cursor: pointer;
  }

  &__trigger-control {
    padding: 12px 16px;
  }
}
</style>
