<template>
  <OwnCard
    class="preview-button-set"
    :class="`preview-button-set--${editorSide}`"
    :radius="cardRadius"
    control
  >
    <div class="h-full">
      <slot></slot>
    </div>
  </OwnCard>
</template>

<script>
import { OwnCard } from '@/ui'
import { useBreakpoints } from '@/utils'

export default {
  name: 'PreviewButtonSet',
  components: { OwnCard },
  props: {
    editorSide: {
      type: String,
      required: true,
      validator: (v) => ['left', 'right'].includes(v),
    },
  },
  setup() {
    const { isDesktop } = useBreakpoints()

    return { isDesktop }
  },
  computed: {
    cardRadius() {
      const { editorSide } = this

      if (this.isDesktop) {
        return '8px'
      }

      return editorSide === 'left' ? '0 8px 8px 0' : '8px 0 0 8px'
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-button-set {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--left {
    left: -16px;
    z-index: 1;

    @include respond-to('md-and-up') {
      left: -40px;
    }
  }

  &--right {
    right: -16px;
    z-index: 1;

    @include respond-to('md-and-up') {
      right: -40px;
    }
  }
}
</style>
