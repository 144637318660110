<i18n locale="en">
{
  "actions": {
    "cancel": "Cancel"
  }
}
</i18n>

<template>
  <div
    :class="[
      'default-full-page-top-bar',
      isDesktop ? 'flex-row' : 'flex-col',
      'justify-center gap-4',
    ]"
  >
    <div
      :class="['flex-row align-center flex-1', isDesktop ? 'gap-8' : 'gap-4']"
    >
      <slot name="left-actions">
        <OwnButton
          :text="isDesktop ? $t('actions.cancel') : undefined"
          @click="$emit('close')"
        >
          <template #icon-left>
            <PhXCircle size="24" />
          </template>
        </OwnButton>
      </slot>

      <OwnType v-if="title" :text="title" variant="title" />
    </div>

    <slot name="center" />

    <div class="flex-row gap-6 justify-end align-center flex-1">
      <TriggerSupport
        v-if="isDesktop"
        v-slot="{ triggerSupport }"
        class="default-full-page-top-bar__support"
      >
        <button @click="triggerSupport">
          <PhChatTeardropText size="24" />
        </button>
      </TriggerSupport>

      <div :class="!isDesktop ? 'w-full' : undefined">
        <slot name="right-actions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  PhChatTeardropText,
  PhXCircle,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { TriggerSupport } from '@/core/support'
import { OwnButton, OwnType } from '@/ui'
import { useBreakpoints } from '@/utils'

withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: undefined,
  }
)

const { isDesktop } = useBreakpoints()
</script>

<style lang="scss" scoped>
.default-full-page-top-bar {
  padding: 16px 24px;
  @include control-shadow();

  &__support {
    flex-shrink: 0;
    height: 24px;
  }
}
</style>
