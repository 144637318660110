<template>
  <OwnCard
    class="own-segmented-control"
    :class="`own-segmented-control--${variant}`"
    control
  >
    <component
      :is="el"
      v-for="option of options"
      :key="option.label"
      class="own-segmented-control__option"
      v-bind="
        el === 'router-link'
          ? {
              to: option.value,
              'active-class': 'own-segmented-control__option--active',
            }
          : {
              class: [
                option.value === selected &&
                  'own-segmented-control__option--active',
              ],
            }
      "
      @click="selected = option.value"
    >
      <slot :item="option">
        <component :is="option.icon" v-if="option.icon" size="24" />
        <OwnType
          v-if="option.label"
          :text="option.label"
          :color="option.value === selected ? 'primary' : 'secondary'"
          variant="button"
        />
      </slot>
    </component>
  </OwnCard>
</template>

<script>
import { OwnCard } from '../OwnCard'
import { OwnType } from '../OwnType'

export default {
  name: 'OwnSegmentedControl',
  components: {
    OwnCard,
    OwnType,
  },
  props: {
    /**
     * Element, use `router-link` to enable navigation
     */
    el: { type: String, default: 'button' },

    /**
     * Options to display
     */
    options: { type: Array, default: () => [] },

    /**
     * @model Active Value
     */
    value: { type: [String, Boolean], default: undefined },

    /**
     * Appearance variant
     */
    variant: {
      type: String,
      default: 'form',
      validator: (v) => ['form', 'navigation'].includes(v),
    },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
// Base of 8px, inside a 1px border -> 8 - 1 = 7px
$child-border-radius: 7px;

.own-segmented-control {
  flex-direction: row;

  &__option {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: $text-color-secondary;
  }

  &--form &__option {
    padding: 8px 12px;

    border-right: 1px dashed $background-divider;

    &--active {
      background-color: $background-selected;
      color: $text-color-primary;
    }

    &:first-child {
      border-top-left-radius: $child-border-radius;
      border-bottom-left-radius: $child-border-radius;
    }

    &:last-child {
      border-right: none;
      border-top-right-radius: $child-border-radius;
      border-bottom-right-radius: $child-border-radius;
    }
  }

  &--navigation {
    gap: 16px;

    width: fit-content;
    white-space: nowrap;

    padding: 12px 16px;
  }

  &--navigation &__option {
    &--active > * {
      color: $misc-brand;
    }
  }
}
</style>
