<template>
  <Responsive @size-change="onSizeChange">
    <div class="default-page-layout flex-col gap-10">
      <div class="flex-col gap-8">
        <PageHeader :title="computedTitle" :subtitle="computedSubtitle">
          <template #subtitle>
            <slot name="subtitle" />
          </template>

          <template #actions>
            <slot name="actions" />
          </template>
        </PageHeader>

        <div v-if="$slots['filter-bar']" class="flex-col gap-10">
          <div class="flex-row gap-3 align-center justify-start">
            <slot name="filter-bar"></slot>
          </div>

          <OwnRule type="dashed" />
        </div>
      </div>

      <CollectionsBanner />

      <slot></slot>
    </div>

    <slot name="dialog"></slot>
  </Responsive>
</template>

<script lang="ts" setup>
import { computed, provide, ref } from 'vue'

import { CollectionsBanner, usePageDescription, usePageTitle } from '@/core'
import { OwnRule, Responsive } from '@/ui'
import { useBreakpoints } from '@/utils'

import PageHeader from './PageHeader.vue'

const props = withDefaults(
  defineProps<{
    maxWidth?: number
    subtitle?: string
    title?: string
  }>(),
  {
    maxWidth: 1440,
    subtitle: undefined,
    title: undefined,
  }
)

const { isDesktop } = useBreakpoints()

const layoutWidth = ref(0)

provide('page-layout__width', layoutWidth)

const maxWidth = computed(() => `${props.maxWidth + 80}px`)

const onSizeChange = ({ width }: { width: number }) => {
  layoutWidth.value = width
}

const pageTitle = usePageTitle()
const pageDescription = usePageDescription()

const computedTitle = computed(() => {
  if (props.title) return props.title

  if (!isDesktop.value) return undefined
  return pageTitle.value
})

const computedSubtitle = computed(() => {
  if (props.subtitle) return props.subtitle

  return pageDescription.value
})
</script>

<style lang="scss" scoped>
.default-page-layout {
  background-color: $background-primary;
  max-width: v-bind(maxWidth);
  height: 100%;
  min-height: 100%;
  padding: 40px 16px;

  @include respond-to('md-and-up') {
    margin: 0 auto;
    padding: 40px 56px;
  }
}
</style>
