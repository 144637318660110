<template>
  <div class="flex-row gap-4 justify-between">
    <OwnType :text="label" :variant="labelVariant" :color="labelColor" />
    <OwnType
      :text="value"
      :variant="valueVariant"
      :color="valueColor"
      :title="valueTitle"
      :tabular="currency"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { OwnType } from '@/ui'

const props = withDefaults(
  defineProps<{
    currency?: boolean
    label: string
    value: string
    valueTitle?: string
    variant?: 'total' | 'subtotal' | 'body'
  }>(),
  {
    currency: false,
    valueTitle: undefined,
    variant: undefined,
  }
)

const labelVariant = computed(() => {
  switch (props.variant) {
    case 'body':
      return 'paragraph'
    case 'subtotal':
      return 'paragraph'
    case 'total':
      return 'subtitle'
    default:
      return 'subtitle'
  }
})

const labelColor = computed(() => {
  if (props.variant === 'body') return 'secondary'
  return 'primary'
})

const valueVariant = computed(() => {
  switch (props.variant) {
    case 'body':
      return 'paragraph'
    case 'subtotal':
      return 'paragraph'
    case 'total':
      return 'subtitle'
    default:
      return 'paragraph'
  }
})

const valueColor = computed(() => {
  if (props.variant === 'body') return 'secondary'
  return 'primary'
})
</script>
