
{
  "title": "Add Brand",
  "actions": {
    "add": "Add Brand | Add Brand | Add Brands"
  },
  "labels": {
    "empty": "No Brand Found"
  }
}
