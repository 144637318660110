<template>
  <OwnDropdown
    v-model="selectedState"
    :filter-keys="['label']"
    :options="stateOptions"
  />
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'

import { OwnDropdown } from '@/ui'

import { US_STATES } from './US_STATES'

export type UsState = (typeof US_STATES)[number]

const props = defineProps<{
  value: UsState
}>()

const emit = defineEmits<(event: 'input', value: UsState) => void>()

const selectedState = useVModel(props, 'value', emit, { eventName: 'input' })

const stateOptions = US_STATES.map((value) => ({ label: value, value }))
</script>
